<template>
    <div class="enter h-font-lg">
        <div class="city">
            <span>投保地区:{{orgInfo&&orgInfo.city}}<!-- <i class="icon"><img class="rotateX" :src="require('./image/select.png')" /></i> --></span>
        </div>
        <div class="select">
            <van-tabs v-model="custType">
                <van-tab title="私家车"></van-tab>
                <van-tab title="企业车"></van-tab>
            </van-tabs>
            <div class="content">
                <van-field v-model="plateNo" :disabled="plateFlag" label="车牌号" maxlength="10" @input="plateNoInput" placeholder="请输入车牌号" />
                <div class="plateNoCheck">
                    <van-checkbox v-model="plateFlag"  icon-size="16px" checked-color="#57ccc2"  shape="square" style="font-size: 12px;">新车未上牌</van-checkbox>
                </div>
                <van-button type="primary" block round @click="toPath">立即查询车险报价</van-button>
                <div class="list">
                    <router-link to="/miniprogram/quote/list"><span>报价记录</span></router-link>
                </div>
            </div>
        </div>
        <!-- <div class="company">
            <div class="head">
                <i class="icon"><img :src="require('./image/head.png')"></i>
                <span>合作保险公司</span>
                <i class="icon"><img class="rotateY" :src="require('./image/head.png')"></i>
            </div>
            <van-row gutter="10">
                <van-col span="8"><img :src="require('./image/pic_PAIC.png')" width="100%"></van-col>
                <van-col span="8"><img :src="require('./image/pic_YGBX.png')" width="100%"></van-col>
                <van-col span="8"><img :src="require('./image/pic_TPIC.png')" width="100%"></van-col>
            </van-row>
            <van-row gutter="10">
                <van-col span="8"><img :src="require('./image/pic_GPIC.png')" width="100%"></van-col>
                <van-col span="8"><img :src="require('./image/pic_BOCI.png')" width="100%" height="52px"></van-col>
            </van-row>
        </div> -->
    </div>
</template>
<script>
import { request } from "@/api/service";
import { mapState } from "vuex";
import store from "@/store";
export default {
  name: "QuoteEnter",
  components: { },
  data() {
    return {
        pageData: {
        },
        orgInfo:null,
        custType:0,
        plateNo:null,
        plateFlag:false
    };
  },
  computed:{
      ...mapState("user", ["info"])
  },
  watch:{
      plateFlag:function(val){
          if(val){
              this.plateNo = null
          }
      }
  },
  created () {
    this.init()
  },
  methods: {
    async init(){
       const res = await request({
                            url: "/organization/org/load",
                            method: "post",
                            data: {id:this.info.issueOrgId},
                        })
        this.orgInfo=res
        store.dispatch("car/setCardImg", {
            vehicle: {
                vehiclelicense: '',
                vehicleInvoice: '',
                vehicleCertificate: ''
            },
            owner: {},
            holder: {},
            insured: {}
        })
    },
    toPath() {
        const quote={
            basis:{
                insuredArea: (this.orgInfo&&this.orgInfo.cityCode)||"110100",
                orgId:this.info.issueOrgId,
                orgCode:this.info.issueOrgId
            },
            vehicle:{
                plateNo:this.plateNo,
                plateFlag:this.plateFlag
            },
            owner:{
                custType:""+this.custType
            }
        }
        this.$store.dispatch("car/setQuote", quote);//保存缓存
        this.$router.push({ path: "/miniprogram/quote/simple" });
    },
    plateNoInput () {
        this.plateNo = this.plateNo.toUpperCase()
    }
  },
};
</script>
<style  lang="scss" scoped>
@import "@/assets/style/var.scss";
.enter{
    background-color: $background-color;
    height: 100vh;
}
.city{
    padding: 20px 0;
    background-color: $white;
    text-align: center;
    font-weight: $font-weight-bold-lg;
    font-size: $font-size-md;
    span{
        padding: 8px 16px;
        border-radius: 16px;
        border:$border-width-base solid $border-color;
        box-shadow: $box-shadow-base;
    }
}
i.icon{
    position: relative;
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
    padding: 0 5px;
    &:before {
        display: inline-block;
    }
    img {
        width: 0.8em;
        height: 0.8em;
        object-fit: contain;
        &.rotateX{
            transform:rotateX(180deg);
            -ms-transform:rotateX(180deg); /* Internet Explorer */
            -moz-transform:rotateX(180deg); /* Firefox */
            -webkit-transform:rotateX(180deg); /* Safari 和 Chrome */
            -o-transform:rotateX(180deg); /* Opera */
        }
        &.rotateY{
            transform:rotateY(180deg);
            -ms-transform:rotateY(180deg); /* Internet Explorer */
            -moz-transform:rotateY(180deg); /* Firefox */
            -webkit-transform:rotateY(180deg); /* Safari 和 Chrome */
            -o-transform:rotateY(180deg); /* Opera */
        }
    }
}
.select{
    margin: 10px;
    background: $white;
    border-radius: $border-radius-lg;
    ::v-deep .van-tabs{
        &__line{
            background-color:$primary-color;
            width: 15px;
            bottom: 22px
        }
    }
    ::v-deep .van-tab{
        padding: 0;
        background-color:$primary-3;
        &--active{
            color: $primary-color;
            background-color:$white;
            font-weight: $font-weight-bold-lg;
        }
    }
    .content{
        padding: 10px;
        .plateNoCheck{
            padding: 15px;
        }
        .list{
            padding: 10px 0;
            text-align: center;
            color: $primary-color;
            font-weight: $font-weight-bold-lg;
            text-decoration:none;
            span{
                text-decoration: underline;
                color: $primary-color;
            }
        }
        ::v-deep .van-cell__title{
            font-weight: $font-weight-bold-lg;
        }
    }
}
.company{
    padding:0 10px;
    .head{
        text-align: center;
        padding: 10px 0;
    }
}
</style>
